import React, { useState } from "react";
import TeacherLoginForm from "./TeacherLoginForm";
import "./LoginFormHolder.css";
import StudentLoginForm from "./StudentLoginForm";

function LoginFormHolder() {
  const [selectedAccount, setSelectedAccount] = useState("student");
  const [buttonStyles, setButtonStyles] = useState({
    student: {
      backgroundColor: "#0d6efd",
      color: "#FFFFFF",
    },
    teacher: {
      backgroundColor: "#FFFFFF",
      color: "#000000",
    },
  });

  const handleAccountSelection = (accountType) => {
    setSelectedAccount(accountType);
    setButtonStyles((prevState) => ({
      ...prevState,
      [accountType]: {
        backgroundColor: "#0d6efd",
        color: "#FFFFFF",
      },
      [selectedAccount]: {
        backgroundColor: "#FFFFFF",
        color: "#000000",
      },
    }));
  };

  return (
    <div>
      <div className="my-4 btn-holder">
        <button
          type="button"
          className="btn btn-top btn-default mx-3"
          onClick={() => handleAccountSelection("student")}
          style={buttonStyles.student}
        >
          Student
        </button>
        <button
          type="button"
          className="btn btn-top btn-default mx-3"
          onClick={() => handleAccountSelection("teacher")}
          style={buttonStyles.teacher}
        >
          Teacher
        </button>
      </div>
      {selectedAccount === "student" ? (
        <StudentLoginForm />
      ) : (
        <TeacherLoginForm />
      )}
      {/* <LoginForm /> */}
    </div>
  );
}

export default LoginFormHolder;
