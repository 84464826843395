import React from "react";
import { useNavigate } from "react-router-dom";
import "./TestSubmittedScreen.css";

function TestSubmittedScreen() {
  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const correctCount = urlParams.get("correctCount");
  const percentage = urlParams.get("percentage");

  return (
    <div className="test-submitted-container">
      <h2>Test Submitted</h2>
      <p>Number of Correct Answers: {correctCount}</p>
      <p>Percentage: {percentage}%</p>
      <button className="return-button" onClick={() => navigate("/")}>
        Return to Home
      </button>
    </div>
  );
}

export default TestSubmittedScreen;
