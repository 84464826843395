import React from "react";
import QuestionComponent from "./QuestionComponent";
import DiagramComponent from "./DiagramComponent";
import MultichoiceComponent from "./MultichoiceComponent";

function TestBodyComponent({
  questionHasDiagram,
  question,
  imageUrl,
  questiontext,
  selectedOptions,
  questionsList,
  index,
  selectedOptionId,
  setSelectedOptions,
  selectedOptionsMap,
}) {
  const handleOptionChange = (optionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedSelectedOptions = [...prevSelectedOptions];
      updatedSelectedOptions[index] = optionId;
      return updatedSelectedOptions;
    });
  };

  return (
    <div className="container-fluid test-body">
      <div className="row mt-3">
        <div className="col">
          <QuestionComponent questiontext={questiontext} />
          <DiagramComponent
            questionHasDiagram={questionHasDiagram}
            imageUrl={imageUrl}
          />
          <MultichoiceComponent
            question={question}
            selectedOptionId={selectedOptionId}
            onOptionChange={handleOptionChange}
            selectedOptionsMap={selectedOptionsMap}
            questionId={question.questionId}
          />
        </div>
      </div>
    </div>
  );
}

export default TestBodyComponent;
