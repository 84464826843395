import React, { useEffect, useState, useRef } from "react";

function TopComponent({ timeAllowed, submithandler, onTimerEnd, testTitle }) {
  const [timeRemaining, setTimeRemaining] = useState(timeAllowed * 60);
  const timerRef = useRef();
  const startTime = useRef(Date.now());

  const updateTimer = () => {
    const elapsedSeconds = Math.floor((Date.now() - startTime.current) / 1000);
    const remainingSeconds = Math.max(timeAllowed * 60 - elapsedSeconds, 0);
    setTimeRemaining(remainingSeconds);

    if (remainingSeconds === 0) {
      onTimerEnd();
    }
  };

  useEffect(() => {
    const timerInterval = 1000;

    const intervalId = setInterval(updateTimer, timerInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeAllowed, onTimerEnd]);

  useEffect(() => {
    const handleClickOutsideTimer = (event) => {
      if (timerRef.current && !timerRef.current.contains(event.target)) {
        // Clicked outside the timer component
        // Proceed with updating the timer
        updateTimer();
      }
    };

    document.addEventListener("click", handleClickOutsideTimer);

    return () => {
      document.removeEventListener("click", handleClickOutsideTimer);
    };
  }, []);

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds % 60).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h3>{testTitle}</h3>
            </div>
            <div>
              <h3>{formatTime(timeRemaining)}</h3>
            </div>
            <div>
              <button className="btn btn-danger" onClick={submithandler}>
                Submit Test
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopComponent;
