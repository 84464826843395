import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
} from "react-router-dom";
import LoginScreen from "./screens/LoginScreen";
import NotfoundScreen from "./screens/NotfoundScreen";
import CBTScreen from "./screens/CBTScreen";
import SelecttestScreen from "./screens/SelectTestScreen";
import TestSubmittedScreen from "./screens/TestSubmittedScreen";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LoginScreen />} />
        <Route exact path="/cbt-screen" element={<CBTScreen />} />
        <Route exact path="/select-test" element={<SelecttestScreen/>}/>
        <Route exact path="/test-submitted" element={<TestSubmittedScreen/>}/>
        <Route exact path="*" element={<NotfoundScreen />} />
      </Routes>
    </Router>
  );
}

export default App;
