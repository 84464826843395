import React from "react";

function QuestionComponent({ questiontext }) {
  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col">
          <h5>{questiontext}</h5>
        </div>
      </div>
    </div>
  );
}

export default QuestionComponent;
