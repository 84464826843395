import React from "react";
import "./MultichoiceComponent.css";

function MultichoiceComponent({
  question,
  onOptionChange,
  selectedOptionsMap,
}) {
  const selectedOptionId = selectedOptionsMap[question.questionId];

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col">
          {question.optionsList.map((option, index) => (
            <div key={option.optionId} className="form-check-i">
              <input
                type="radio"
                id={option.optionId}
                name={`question_${question.questionId}`}
                className="form-check-input-i"
                value={option.optionId}
                checked={selectedOptionId === option.optionId}
                onChange={() => onOptionChange(option.optionId)}
              />
              <label htmlFor={option.optionId} className="form-check-label">
                {option.optionText}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MultichoiceComponent;
