import React from "react";

function CustomAlertDialog({ title, message, onCancel, onConfirm }) {
  return (
    <div className="custom-alert-dialog-overlay">
      <div className="custom-alert-dialog">
        <h3>{title}</h3>
        <p>{message}</p>
        <div className="button-container">
          <button className="btn btn-secondary mx-4" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn btn-primary mx-2" onClick={onConfirm}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomAlertDialog;
