import React from "react";

function DiagramComponent({ questionHasDiagram, imageUrl }) {
  if (questionHasDiagram === "0") {
    return null;
  }

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col">
          <img
            src={imageUrl}
            alt="Diagram"
            style={{
              maxHeight: "25vh",
              maxWidth: "75vh",
              objectFit: "contain",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default DiagramComponent;
