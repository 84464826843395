import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SelectTestScreen.css";

function SelecttestScreen() {
  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState("");
  const [testInfo, setTestInfo] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchTests();
  }, []);

  const fetchTests = () => {
    fetch("https://students.cbt.thecribbers.ng/backend/api/php/fetchTests.php")
      .then((response) => response.json())
      .then((data) => {
        setTests(data);
      })
      .catch((error) => {
        console.error("Error fetching tests:", error);
      });
  };

  const handleTestSelect = (event) => {
    const selectedTestId = event.target.value;
    setSelectedTest(selectedTestId);

    const selectedTestInfo = tests.find(
      (test) => test.testId === selectedTestId
    );
    setTestInfo(selectedTestInfo);
  };

  const handleStartTest = () => {
    if (selectedTest) {
      navigate(`/cbt-screen?testId=${selectedTest}`);
    }
  };

  return (
    <div className="select-test-screen">
      <h1>Select Test</h1>
      <select onChange={handleTestSelect} value={selectedTest}>
        <option value="">Select a test</option>
        {tests.map((test) => (
          <option key={test.testId} value={test.testId}>
            {test.testTitle}
          </option>
        ))}
      </select>
      {testInfo && (
        <div className="test-info-container">
          <div className="test-info-box">
            <p>
              <strong>Test Title:</strong> {testInfo.testTitle}
            </p>
            <p>
              <strong>Subject:</strong> {testInfo.subject}
            </p>
            <p>
              <strong>Class Level:</strong> {testInfo.classLevel}
            </p>
            <p>
              <strong>Time Allocated:</strong> {testInfo.timeAllocated} minutes
            </p>
            <p>
              <strong>Number of Questions:</strong> {testInfo.numberOfQuestions}
            </p>
          </div>
        </div>
      )}
      <button
        className="start-test-button"
        disabled={!selectedTest}
        onClick={handleStartTest}
      >
        Start Test
      </button>
    </div>
  );
}

export default SelecttestScreen;
