import React from "react";
import "./LoginForm.css";

function TeacherLoginForm() {
  return (
    <div className="cover mx-5">
      <h1 className="my-2">Login</h1>
      <p className="txt-a">As a Teacher</p>
      <div className="p-1 mx-4 my-1">
        <input type="text" placeholder="Username" className="form-control" />
      </div>
      <div className="my-1 mx-4 p-1">
        <input
          type="password"
          placeholder="Password"
          className="form-control"
        />
      </div>
      <div className="my-1">
        <div className="btn btn-primary">Login</div>
      </div>
    </div>
  );
}

export default TeacherLoginForm;
