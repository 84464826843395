import React from "react";
import "./BottomComponent.css";

function BottomComponent({ handleBack, handleNext, index, idx }) {
  return (
    <div className="bottom-component">
      <div className="container">
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-primary btn-sm equal-width"
            onClick={handleBack}
          >
            {index <= 0 ? "Quit Test" : "Previous"}
          </button>
          <button
            className="btn btn-primary btn-sm equal-width"
            onClick={handleNext}
          >
            {index === idx ? "Submit" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default BottomComponent;
