import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import "./LoginForm.css";

function StudentLoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginSuccessful, setLoginSuccessful] = useState(false);

  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    setLoginSuccessful(true);
    //Anthony would handle the login logic here
  };

  useEffect(() => {
    if (loginSuccessful) {
      navigate("/select-test");
    }
  }, [loginSuccessful, navigate]);

  return (
    <div className="cover mx-5">
      <h1 className="my-2">Login</h1>
      <p className="txt-a">As a Student</p>
      <div className="p-1 mx-4 my-1">
        <input
          type="text"
          placeholder="Username"
          className="form-control"
          value={username}
          onChange={handleUsernameChange}
        />
      </div>
      <div className="my-1 mx-4 p-1">
        <input
          type="password"
          placeholder="Password"
          className="form-control"
          value={password}
          onChange={handlePasswordChange}
        />
      </div>
      <div className="my-1">
        <div className="btn btn-primary" onClick={handleSubmit}>
          Login
        </div>
      </div>
    </div>
  );
}

export default StudentLoginForm;
