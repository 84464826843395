import React from "react";
import TopComponent from "./TopComponent";

function TopBar() {
  const student = {
    imageUrl:
      "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
    fullName: "Bishop Olumayor",
    studentId: "123456789",
  };

  return (
    <div
      className="top-bar rounded-top rounded-bottom"
      style={{
        backgroundColor: "#0d6efd",
        color: "white",
        padding: "10px",
        display: "flex",
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.5)",
        marginTop: "20px",
        // borderRadius: "10",
      }}
    >
      <div className="student-info d-flex align-items-center">
        <img
          src={student.imageUrl}
          alt="Student Image"
          className="avatar"
          style={{
            width: "50px",
            height: "50px",
            objectFit: "cover",
            borderRadius: "10%",
          }}
        />
        <div className="student-info ml-3 ms-4">
          <p className="mb-0">{student.fullName}</p>
          <p className="mb-0">ID: {student.studentId}</p>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
