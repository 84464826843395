import LoginForm from "../components/LoginForm";
import LoginFormHolder from "../components/LoginFormHolder";
import "./LoginScreen.css";

function LoginScreen() {
  return (
    <div className="d-flex justify-content-end align-items-center page">
      <LoginFormHolder />
    </div>
  );
}

export default LoginScreen;
