import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopComponent from "../cbt_components/TopComponent";
import TestBodyComponent from "../cbt_components/TestBodyComponent";
import BottomComponent from "../cbt_components/BottomComponent";
import TopBar from "../cbt_components/TopBar";
import "./CBTScreen.css";
// import test1 from "../data/DummyTest";
import CustomAlertDialog from "../cbt_components/CustomAlertDialog";
import "../cbt_components/CustomAlertDialog.css";

function CBTScreen() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const testId = searchParams.get("testId");
  const [index, setIndex] = useState(0);
  const [timeAllowed, setTimeAllowed] = useState(10);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSubmitConfirmationVisible, setIsSubmitConfirmationVisible] =
    useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [testInfo, setTestInfo] = useState(null);
  const [testTitle, setTestTitle] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (testInfo === null) {
      fetchTestDetails();
    }
  }, [testInfo]);

  const fetchTestDetails = () => {
    fetch(
      `https://students.cbt.thecribbers.ng/backend/api/php/retrieveCBTtest.php?testId=${testId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setTestInfo(data);
        allocateTestInfo(data);
        setSelectedOptions(Array(data.questionsList.length).fill(""));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function allocateTestInfo(data) {
    setTimeAllowed(data.timeAllocated);
    setTestTitle(data.testTitle);

    const selectedOptionsMap = {};
    data.questionsList.forEach((question, i) => {
      const questionId = question.questionId;
      selectedOptionsMap[questionId] = selectedOptions[i] || "";
    });
    setSelectedOptions(selectedOptionsMap);
  }

  function handleBack() {
    if (index !== 0) {
      setIndex((prevIndex) => prevIndex - 1);
    }
  }

  function handleNext() {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedSelectedOptions = [...prevSelectedOptions];
      updatedSelectedOptions[index] = selectedOptions[index];
      return updatedSelectedOptions;
    });
    setIndex((prevIndex) => {
      if (prevIndex < testInfo.questionsList.length - 1) {
        return prevIndex + 1;
      } else {
        submitTest();
      }
      return prevIndex;
    });
    // console.log(selectedOptionsMap);
  }

  function submitTest() {
    setDialogMessage("You are about to submit this test. Are you sure?");
    openSubmitConfirmation();
  }

  function testSubmition() {
    setIsSubmitConfirmationVisible(false);
    if (testInfo) {
      const correctOptionsMap = new Map();
      testInfo.questionsList.forEach((question) => {
        const correctOption = question.optionsList.find(
          (option) => option.isAnswer === "1"
        );

        correctOptionsMap.set(question.questionId, correctOption.optionId);
      });

      const convertedSelectedOptionsMap = new Map();

      Object.entries(selectedOptionsMap).forEach(([questionId, optionId]) => {
        convertedSelectedOptionsMap.set(questionId, optionId);
      });

      let correctCount = 0;

      convertedSelectedOptionsMap.forEach((selectedOptionId, questionId) => {
        const correctOptionId = correctOptionsMap.get(questionId);
        if (selectedOptionId === correctOptionId) {
          correctCount++;
        }
      });

      const percentage = ((correctCount / testInfo.questionsList.length) * 100).toFixed(2);

      navigate(`/test-submitted?correctCount=${correctCount}&percentage=${percentage}`);

    }
  }

  function handleTimerEnd() {
    testSubmition();
    console.log("Timer has ended");
  }

  function quitTest() {
    setDialogMessage("You are about to quit this test. Are you sure?");
    openSubmitConfirmation();
  }

  const selectedOptionsMap = {};

  if (testInfo) {
    testInfo.questionsList.forEach((question, i) => {
      const questionId = question.questionId;
      selectedOptionsMap[questionId] = selectedOptions[i];
    });
  }

  function handleCancelSubmit() {
    setIsSubmitConfirmationVisible(false);
  }

  function handleConfirmSubmit() {
    setIsSubmitConfirmationVisible(false);
    testSubmition();
  }

  function openSubmitConfirmation() {
    setIsSubmitConfirmationVisible(true);
  }
  useEffect(() => {
    if (isSubmitConfirmationVisible) {
      document.body.classList.add("cbt-body");
    } else {
      document.body.classList.remove("cbt-body");
    }
  }, [isSubmitConfirmationVisible]);

  return (
    <div className="container-fluid-i cbt-screen">
      {testInfo ? (
        <>
          <TopBar />
          <div className="row mt-3">
            <div className="col">
              <TopComponent
                testTitle={testTitle}
                timeAllowed={timeAllowed}
                submithandler={submitTest}
                onTimerEnd={handleTimerEnd}
              />
              <TestBodyComponent
                questionHasDiagram={testInfo.questionsList[index].hasDiagram}
                question={testInfo.questionsList[index]}
                imageUrl={testInfo.questionsList[index].diagramUrl}
                questiontext={testInfo.questionsList[index].questionText}
                index={index}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                selectedOptionsMap={selectedOptionsMap}
              />
              <div className="bcm">
                <BottomComponent
                  handleBack={index === 0 ? quitTest : handleBack}
                  handleNext={handleNext}
                  index={index}
                  idx={testInfo.questionsList.length - 1}
                />
              </div>
            </div>
          </div>
          {isSubmitConfirmationVisible && (
            <CustomAlertDialog
              title="Confirmation"
              message={dialogMessage}
              onCancel={handleCancelSubmit}
              onConfirm={handleConfirmSubmit}
            />
          )}
        </>
      ) : (
        <>
          <div className="loading-spinner-container">
            <div className="loading-spinner"></div>
          </div>
        </>
      )}
    </div>
  );
}

export default CBTScreen;
